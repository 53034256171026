<template>
    <div>
        <Header>
            <template v-slot:left></template>
            <template v-slot:mid>
                新闻
            </template>
        </Header>
        <div class="col-wrap ">
            <img src="@/assets/mobile/news/banner-bg.jpg" />
            <div class="mask banner">
                <h3>十七年成长突破</h3>
                <h1>见证进取的里程碑</h1>

                <p>Seventeen years’ development for remarkable progress</p>
            </div>
        </div>
        <div class="col-wrap news">
            <img class="news-tit" src="@/assets/mobile/news/tit.png" />
            <p style="font-size:12px; color:#666; margin:0 20px 20px 20px;">十七年成长突破 见证进取的里程碑</p>
            <ul class="list">
                <li v-for="item in news" :key="item.id">
                    <a :href="'/m/news/detail?id=' + item.id">
                        <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,w_345,h_195'" />
                        <h3>{{ item.title }}</h3>
                        <p class="line2">{{ item.desc }}</p>
                    </a>
                </li>
            </ul>

            <div class="blue-btn" :class="loading ? 'disabled' : ''" @click="loadData"><i v-if="loading" class="iconfont icon-loading"></i>查看更多新闻</div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
import settings from "@/settings";
export default {
    name: "mobile-news",
    components: { Header, Footer },
    data() {
        return { loading: false, resourceBaseUrl: settings.resourceBaseUrl, news: [], page: 0, ended: false };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.loading = true;
            this.$http
                .get(`/api/news/list?page=${this.page + 1}`)
                .then((res) => {
                    this.page++;
                    this.news = this.news.concat(res.data);
                    this.ended = res.data.length < 6;
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/mobile.css");
.blue-btn {
    margin-bottom: 60px;
}
.news .list {
    margin: 20px 15px 40px 15px;
    display: flex;
    flex-direction: column;
    border: none;
}
.list li {
    border-bottom: 1px #ccc solid;
    margin-bottom: 20px;
}

.list li h3 {
    text-align: left;
    color: #333;
    font-size: 15px;
    margin: 10px 0;
}
.list li p {
    text-align: left;
    color: #666;
    font-size: 13px;
    margin: 10px 0 20px 0;
}

.list li img {
    border-radius: 10px;
}

.news-tit {
    width: 50%;
    margin: 20px 0 0 0;
}
.banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner h1 {
    font-size: 20px;
    margin: 20px 15px;
}
.banner h3 {
    font-size: 17px;
}
.banner p {
    font-size: 12px;
    margin: 0 15px;
}
</style>
